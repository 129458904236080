h1, h2, h3, h4, h5 {
    display: inline;
    margin-top:0;
    margin-bottom:0;

    color:black;
}
p {
  font-size: 1.2em;
}
a {
    display:inline-block;
}
.buttonW {
    width: "100%";
    height: "310px";}

    .buttonD {
        width: "100%";
        height: "310px";
        color: white;
    }

    .MuiSlider-thumb.MuiSlider-thumbColorPrimary{
        height: 0.9em;
        width: 0.9em;
        font-size: 1em;
        color: #ff6a03;
    }
    .MuiSlider-root.MuiSlider-colorPrimary.MuiSlider-marked{
        height: 1.3em;
        width: 18em;
        font-size: 1em;
        color:hsl(295, 93%, 48%);

        margin-left: 1px;
        margin-right: 1px;
    }
    .MuiInputBase-input.MuiInput-input.MuiInputBase-inputMarginDense.MuiInput-inputMarginDense{

        margin-left: 10px;
        margin-right: 5px;
        height: 35px;
        width: 5em;
        font-size: 22px;
        color: #ff6a03;
        
    }
    .myHeader{
        text-align: left;

        margin-left: 25%;
    }
    .makeStyles-slider-23{
        width: 100% !important;
        max-width: 20px !important;
      }

      .Bab{
        width: 100% !important;
        max-width: 90% !important;
      }
      .MuiAlert-root{


        width: 100% !important;
      }


  /* Centered text */
  .top-under {
    position: relative;
    top: -500px;
    left: 70%;
    transform: translate(-50%, -50%);
    font-size:3em;
    color: white;
    font-weight:bold;
  }
  
    /* Centered text */
    .bottom-right {
        position: absolute;
        top: 42%;
        left: 32%;
        font-size:3em;
        color: white;
        font-weight:bold;

      }  /* Centered text */
      .centered {
        position: absolute;
        top: 75%;
        left: 28%;
        font-size:1.8em;
        color: white;
        font-weight:bold;
      }
.LOST {
    position: relative;
    justify-content: center;
}
 /* Centered text */
 .centered2 {
  position: absolute;
  top: 75%;
  left: 22%;
  font-size:1.8em;
  color: white;
  font-weight:bold;
}

  
    /* Centered text */
    .bottom-right4 {
      position: absolute;
      top: 42%;
      left: 20%;
      font-size:3em;
      color: white;
      font-weight:bold;
      }  /* Centered text */
     
      .LOST2 {
        position: relative;
        justify-content: center;
    }
    .LOST3 {
      position: relative;
      justify-content: center;
  }
  .makeStyles-box-37{
   padding:0px; 
  }
  .makeStyles-box-10{
    padding:0px; 
   }